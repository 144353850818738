* {
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  src: url("./ui/assets/fonts/Helvetica-Oblique.ttf") format("truetype");
}
@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: normal;
  src: url("./ui/assets/fonts/Helvetica-Oblique.ttf") format("truetype");
}
@font-face {
  font-family: "Helvetica";
  font-style: italic;
  font-weight: bold;
  src: url("./ui/assets/fonts/Helvetica-BoldOblique.ttf") format("truetype");
}
@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  src: url("./ui/assets/fonts/Helvetica.ttf") format("truetype");
}
@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: normal;
  src: url("./ui/assets/fonts/Helvetica.ttf") format("truetype");
}
@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: bold;
  src: url("./ui/assets/fonts/Helvetica-Bold.ttf") format("truetype");
}
